var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-2",attrs:{"tile":""}},[_c('v-system-bar',{attrs:{"color":_vm.taskRun.state,"height":5,"absolute":""}}),_c('CardTitle',{attrs:{"title":_vm.taskRun.task.name,"icon":"pi-task-run","subtitle":_vm.taskRun.state,"params":{ name: 'task', params: { id: _vm.taskRun.task.id } }}}),_c('v-list-item',{staticClass:"px-0",attrs:{"dense":"","two-line":"","to":{ name: 'flow-run', params: { id: _vm.taskRun.flow_run.id } }}},[_c('v-list-item-avatar',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("pi-flow-run")])],1),_c('v-list-item-content',[_c('span',{staticClass:"text-caption mb-0"},[_vm._v(" Flow Run ")]),_c('v-list-item-title',{staticClass:"text-body-2"},[_c('span',[_vm._v(_vm._s(_vm.taskRun.flow_run.name))])]),_c('v-list-item-title',{staticClass:"text-caption"},[_c('span',{class:((_vm.taskRun.flow_run.state) + "--text")},[_vm._v(" "+_vm._s(_vm.taskRun.flow_run.state)+" ")])])],1)],1),_c('v-card-text',{staticClass:"pl-12 py-0"},[_c('v-list-item',{staticClass:"px-0",attrs:{"dense":""}},[(_vm.taskRun.state_message)?_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text-caption"},[_vm._v(" Last State Message ")]),_c('div',{staticClass:"text-subtitle-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"text-caption"},on),[_vm._v(" ["+_vm._s(_vm.formDate(_vm.taskRun.state_timestamp))+"]: ")])]}}],null,false,2875119838)},[_c('div',[_vm._v(" "+_vm._s(_vm.formatTime(_vm.taskRun.state_timestamp))+" ")])]),_vm._v(" "+_vm._s(_vm.taskRun.state_message)+" ")],1)],1):_vm._e()],1),_c('v-list-item',{staticClass:"pa-0",attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text-caption"},[(_vm.taskRun.state == 'Mapped')?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Expected Mapped Runs ")]),_c('v-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.expectedRuns)+" ")])]}}],null,false,3417003547)},[(!_vm.taskRun.serialized_state.n_map_states)?_c('span',[_vm._v(" This data is only available on Flows registered with Prefect Core 0.13.5+ ")]):_c('span',[_vm._v(" The number of mapped children expected to run. Note that the number of active mapped runs may be less than this if some have not begun running yet. ")])])],1)],1):_vm._e(),(_vm.taskRun.start_time)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Started ")]),_c('v-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formDate( _vm.taskRun.start_time || _vm.taskRun.scheduled_start_time ))+" ")])]}}],null,false,2456603394)},[_c('div',[_vm._v(" "+_vm._s(_vm.formatTime( _vm.taskRun.start_time || _vm.taskRun.scheduled_start_time ))+" ")])])],1)],1):_vm._e(),(_vm.taskRun.end_time)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Ended ")]),_c('v-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formDate(_vm.taskRun.end_time))+" ")])]}}],null,false,1276094835)},[_c('div',[_vm._v(" "+_vm._s(_vm.formatTime(_vm.taskRun.end_time))+" ")])])],1)],1):_vm._e(),(_vm.taskRun.start_time)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Duration ")]),_c('v-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"6"}},[(_vm.taskRun.start_time)?_c('DurationSpan',{attrs:{"start-time":_vm.taskRun.start_time,"end-time":_vm.taskRun.end_time}}):_c('span',[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1)],1)],1):_vm._e(),(_vm.taskRun.updated)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Updated ")]),_c('v-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formDate(_vm.taskRun.updated))+" ")])]}}],null,false,2634491779)},[_c('div',[_vm._v(" "+_vm._s(_vm.formatTime(_vm.taskRun.updated))+" ")])])],1)],1):_vm._e(),(
              _vm.taskRun.serialized_state && _vm.taskRun.serialized_state._result
            )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Result Type ")]),_c('v-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm._f("typeClass")(_vm.taskRun.serialized_state._result.type))+" ")])],1):_vm._e(),(
              _vm.taskRun.serialized_state && _vm.taskRun.serialized_state._result
            )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Result Location ")]),_c('v-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({staticClass:"cursor-pointer show-icon-hover-focus-only pa-2px",attrs:{"role":"button"},on:{"click":function($event){return _vm.copyTextToClipboard(
                        _vm.taskRun.serialized_state._result.location
                      )}}},on),[(_vm.taskRun.serialized_state._result.location)?_c('v-icon',{staticClass:"mb-2px mr-2",attrs:{"x-small":"","tabindex":"0"}},[_vm._v(" "+_vm._s(_vm.copiedText[_vm.taskRun.serialized_state._result.location] ? 'check' : 'file_copy')+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.taskRun.serialized_state._result.location || 'None')+" ")],1)]}}],null,false,2928226652)},[_c('div',[_vm._v(" "+_vm._s(_vm.taskRun.serialized_state._result.location || 'None')+" ")])])],1)],1):_vm._e()],1)],1)],1),(
        _vm.taskRun.task &&
          _vm.$options.filters.typeClass(_vm.taskRun.task.type) == 'Parameter'
      )?_c('v-list-item',{staticClass:"px-0",attrs:{"dense":"","two-line":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('div',{staticClass:"text-caption mb-0"},[_vm._v(" Parameter: ")]),_c('code',{staticClass:"mt-3 pa-3 no-before-after-content code-custom"},[_vm._v(_vm._s(_vm.filteredParams)+" ")])])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }