var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-2 mt-2",attrs:{"tile":""}},[_c('CardTitle',{attrs:{"title":_vm.tableTitle,"icon":"pi-task-run"}},[_c('v-text-field',{staticClass:"task-search",staticStyle:{"min-width":"300px"},attrs:{"slot":"action","flat":"","solo":"","prepend-inner-icon":"search","hide-details":"","placeholder":"Search by run name or map index"},slot:"action",model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"truncate-table",class:{ 'fixed-table': _vm.$vuetify.breakpoint.smAndUp },attrs:{"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [10, 15, 25, 50],
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'keyboard_arrow_left',
        nextIcon: 'keyboard_arrow_right',
        showCurrentPage: true
      },"single-select":"","headers":_vm.headers,"header-props":{ 'sort-icon': 'arrow_drop_up' },"items":_vm.taskRuns,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading > 0,"must-sort":"","item-key":"id","page":_vm.page,"server-items-length":_vm.serverItemsLength,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"calculate-widths":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'task-run', params: { id: item.id } }}},[_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.name || _vm.taskRun.task.name)+" ")])])]}}],null,true)},[(item.name)?_c('span',[_vm._v(" "+_vm._s(item.name)+" "),(item.map_index > -1)?_c('span',[_vm._v(" ("+_vm._s(_vm.taskRun.task.name)+" Child "+_vm._s(item.map_index.toLocaleString())+") ")]):_vm._e()]):(item.map_index > -1)?_c('span',[_vm._v(" "+_vm._s(_vm.taskRun.task.name)+" Child "+_vm._s(item.map_index.toLocaleString())+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.taskRun.task.name)+" Parent ")])])]}},{key:"item.map_index",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.map_index === -1 ? 'Parent' : item.map_index.toLocaleString())+" ")])]}},{key:"item.start_time",fn:function(ref){
      var item = ref.item;
return [(item.start_time)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formDate(item.start_time)))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.formatTime(item.start_time)))])]):_c('span',[_vm._v("...")])]}},{key:"item.end_time",fn:function(ref){
      var item = ref.item;
return [(item.end_time)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.formDate(item.end_time)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.formatTime(item.end_time)))])]):_c('span',[_vm._v("...")])]}},{key:"item.duration",fn:function(ref){
      var item = ref.item;
return [(item.start_time)?_c('DurationSpan',{attrs:{"start-time":item.start_time,"end-time":item.end_time}}):_c('span',[_vm._v("...")])]}},{key:"item.state",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1 pointer",attrs:{"small":"","color":item.state}},on),[_vm._v(" brightness_1 ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.state))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }